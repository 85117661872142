@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@font-face {
  font-family: "Inter", sans-serif;
  src: url("./fonts/GT Alpina/gt alpina ttf/GTAlpina-Rg.ttf");
}
/* brand colors and other variables*/
:root {
  --primary-blue: #83B9F7;
  --primary-yellow: #E8E200;
  --primary-lavender: #DCB8F6;
  --primary-orange: #FF7700;
  --primary-pink: #FF80B8;

  --secondary-blue-light: #2A85F4;
  --secondary-blue-med: #265992;
  --secondary-blue-dark: #1A3251;

  --secondary-green-light: #8BD392;
  --secondary-green-med: #269375;
  --secondary-green-dark: #004C4F;
  
  --secondary-purple-light: #EEE3FF;
  --secondary-purple-med: #9A95E1;
  --secondary-purple-dark: #5C50BD;

  --secondary-yellow-light: #F3E6BB; /*HEX ON BRAND GUIDELINES IS INCORRECT AND PRODUCES A LIGHT BLUE COLOR (TODO)*/
  --secondary-yellow-med: #FFCE45;
  --secondary-yellow-dark: #FF9E00;

  --secondary-pink-light: #FFE6FF;
  --secondary-pink-med: #FFB2E5;
  --secondary-pink-dark: #EA5FD8;

  --logo-height: 8vw;
  --min-logo-height-inches: 1;
  overflow-x: hidden;
}

/* base styles */
* {
  z-index: 0;
  position: relative;
  margin: 0px;
  padding: 0px;
  color: #000;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 75% */
}

h1 {
    /* H1 */
    font-family: Inter;
    font-size: 6.6vh;
    font-style: normal;
    font-weight: 400;
    line-height: 8.3vh; /* 125% */
    text-align: center;
}

h2 {
  font-size: 0.97173654351rem;
}

h3 {
  font-size: 0.7639320225rem;
}

h4 {
  font-size: 0.600566212rem;
}

h5 {
  font-size: 0.472135955rem;
}

h6 {
  font-size: 0.37117033151rem;
}

a {
  color: var(--Neutral-0, #000);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font; */
}

b,em {
  color: var(--Neutral-0, #000);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

i {
  color: var(--Neutral-0, #000);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */

  font-style: italic;
}

li{
  align-self: stretch;
  
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  margin-left: 0vw;
}

sup{
  font-size: 0.4rem;
}

table{
  font-size: 0.75rem;
  align-content: start;
}

th{
  font-size: 0.75rem;
}

tr{
  font-size: 0.75rem;
}

td{
  align-self: stretch;
  
  color: var(--Neutral-0, #000);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  
  padding-bottom: .5em;
  border-top-style: solid;
  border-width: 1px;
}

table.comparison th, table.comparison td {
  width: 50%;
}

table.comparison td {
  padding: 20px;
}

.navbar {
  z-index: 10;
  position: relative;
  align-items: center;
  justify-content:space-around;
  height: fit-content;
  background: #FFF 108.93%;
  display: flex;
}


.site-title {
  padding: 3vw;

  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.32px;
}

.nav-links {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.nav-header {
  position: relative;
  padding: 1vw;
  min-width:max-content;
}

.nav-hamburger-container {
  height: calc(max(min(36px, 3vw), 16px) + 2vw);
}

@media (max-width: 1000px) {
  .nav-hamburger-container {
    grid-column: 2 / -1;
  }
}

.nav-hamburger-menu {
  height: 100%;
  width: 1.37rem;
  margin-right: 1vw;
}

@media (min-width: 1001px) {
  .nav-links {
    position: relative;
  }
  .nav-hamburger-container {
    display: none;
    position: absolute;
  }
}

@media (max-width: 1000px) {
  .nav-links {
    visibility: hidden;
    position: absolute;
  }
  .nav-hamburger-container {
    visibility: visible;
    position: relative;
    grid-column: 24/-1;
  }
}

.nav-title {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.navbar a {
  position: relative;
  text-decoration: none;
  font-size: 0.7rem;
  padding-right: 1px;
}

.navbar a:hover {
  position: relative;
  color: #83B9F7;
}

footer {
  z-index: 9;
  background-color: #000;
  opacity: 70%;

  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 14vh;
  padding: 10px 0;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.footer-links *{
  z-index: 9;
  color: var(--Neutral-0, #FFF);
  font-family: Inter;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.footer-links {
  display: flex;
  flex-direction: row;
  padding: 0px 20vw;
  width: 60vw;
  justify-content: space-evenly;
}

.footer .copyright {
  position: relative;
  align-self: unset;
  width: 50%;
  text-align: center;
}

.down-carrot {
  font-size: small;
}

.speakerall-img {
  width: 100%;
}

a:has(.widget-in-cameo) {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3vh 0 0;
  column-span: 0px;
  text-decoration: none;
}

.widget-in-cameo{
  height: fit-content;
  width: 100%;
  align-self: center;
  scale: 80%;
  border:none
}

.caption {
  color: #000;
  text-align: right;
  width: 20vw;
  align-self: flex-end;
  
  /* Image Subtitle Text */
  font-family: Inter;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.content-toolbox {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}

.content-toolbox a {
  text-decoration: none;
}

.toolbox-item {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
}

.toolbox-item-label {
  text-align: center;
}

@media (min-width: 1400px) {
  .toolbox-container {
    width: 21%;
    height: auto;
  }
}
@media (max-width: 1400px) and (min-width: 1124px) {
  .toolbox-container {
    width: 29%;
    height: auto;
  }
}
@media (max-width: 1124px) {
  .toolbox-container {
    width: 45%;
    height: auto;
  }
}

.toolbox-container {
  margin-bottom: 5vw;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  padding: 1vw;
}

.toolbox-container:hover {
  text-decoration:underline;
  transition: text-decoration 500ms;
  outline: solid;}

.toolbox-item img {
  width: 100%;
}

.navbar-expanded-menu {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  column-count: 2;
  flex-direction: column;
  padding: 0.5vw;
  z-index: 1;
  background-color: white;
  border: 1px solid black;
  width: fit-content;
}

.nav-expansion {
  position: relative;
  width: 14vw;
  padding: 0.35vw;
}

.copyright {
  padding:10px;
  border-top: solid lightgray;
  color:gray;
  text-align:right;
  vertical-align:middle;
  align-self: end;
  font-size: 0.55rem;
}

.article {
  text-align: center;
}

.article-container {
  display: flex;
  width: 40vw;
  flex-direction: column;
  align-items: center;
  gap: 88px;
  padding: 2vh 30vw 204px 30vw;

  background: #FFF;
}

.widget-container {
  display: flex;
  width: 68vw;
  flex-direction: column;
  align-items: center;
  gap: 88px;
  padding: 2vh 16vw 204px 16vw;

  background: #FFF;
}

.front-matter {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 51px;
  align-self: stretch;
}

.metadata {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px 0px;
  justify-content: space-between;
  align-items: center;

  border-top: 1px solid var(--Neutral-80, #C7C9D1);
  border-bottom: 1px solid var(--Neutral-80, #C7C9D1);
}

.metadatum1 {
  color: var(--Neutral-60, #8F93A3);

  /* Body 1 */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.metadatum2 {
  color: var(--Neutral-60, #8F93A3);
  text-align: right;
  /* Body 1 */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.title {
  color: #000;
  align-self: stretch;
}

.speaker-img {
  width: 100%;
  height: fit-content;
  flex-shrink: 0;

  border-radius: 20px;
  background: lightgray 50% / cover no-repeat;
}

.subtitle {
  padding: 5px 0;
  line-height: 40px;
}

.article-body{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: stretch;
}

.abstract-container {
  display: flex;
  width: 100%;
  padding: 32px 0px;
  margin-bottom: 32px;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  border-top: 1px solid var(--Neutral-80, #C7C9D1);
  border-bottom: 1px solid var(--Neutral-80, #C7C9D1);
}

.abstract-title {
  display: flex;
  padding: 0 0 32px 0
}

.abstract {
  width: 40vw;
  flex-shrink: 0;

  color: var(--Neutral-60, #8F93A3);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.abstract li {
  margin-left: 0vw;
  width: 100%;
  color: var(--Neutral-60, #8F93A3);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.abstract li a {
  text-decoration: none;

  color: var(--Neutral-60, #8F93A3);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.subcameo-subheading {
  padding-bottom: 24px;
  align-self: stretch;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 166.667% */
}

.subcameo-subheading a {
  align-self: stretch;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 166.667% */
}

.analysis-subheading {
  padding-bottom: 48px;
  align-self: stretch;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 166.667% */  
}

.analysis {
  padding-bottom: 88px;
  align-self: stretch;
  
  color: var(--Neutral-0, #000);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.blurb {
  width: 100%;
  align-self: flex-start;
  text-align: left;

  color: var(--Neutral-0, #000);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.scroll-top-button {
  z-index: 11;
  opacity: 0;
  display: none;
  cursor: default;

  position: fixed;
  bottom: 2vw;
  right: 2vw;
  width: fit-content;

  font-family: Inter;
  font-size: 2vw;
  font-weight: 600;
  color: #000;
  padding: 1vw;
  border-radius: 1vw;
}

.scroll-top-button:hover {
  z-index: 11;

  cursor: pointer;
  background: #757575a8;
  transition: background 500ms;
}

.scroll-top-button:not(:hover) {
  z-index: 11;

  background: #75757553;
  transition: background 500ms;
}

.scroll-top-button.show {
  z-index: 11;
  opacity: 1;
  display: block;
  transition: opacity 500ms;
}

.landing-page {
  z-index: -1;
  width: 100vw;
  height: 550vh;
  background: #FFF;
  overflow: hidden;

  /* Shadow */
  box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.20);
}

.bg-top-gradient-1 {
  z-index: 1;
  position: absolute;
  top: 0vh;
  width: 100vw;
  height: 147vh;
  flex-shrink: 0;
  background: rgba(133, 142, 147, 0.50);
}

.bg-top-gradient-2 {
  z-index: 1;
  position: absolute;
  top: 0vh;
  width: 100vw;
  height: 1965px;
  flex-shrink: 0;
  background: linear-gradient(180deg, rgba(185, 177, 199, 0.00) 0%, rgba(255, 255, 255, 0.50) 42.46%, #FFF 84.92%);
}

.bg-top-gradient-3 {
  z-index: 3;
  position: absolute;
  top: 0vh;
  left: -3.4vw;
  width: 103vw;
  height: 111vh;
  flex-shrink: 0;
  background: linear-gradient(330deg, rgba(11, 29, 38, 0.00) 29.06%, #0B1D26 108.93%);
  filter: blur(25px);
}

.bg-top-gradient-4 {
  z-index: 1;
  position: absolute;
  top: 0vh;
  bottom: 280vh;
  width: 100vw;
  height: 187vh;
  flex-shrink: 0;
  background: linear-gradient(180deg, rgba(185, 177, 199, 0.00) 0%, rgba(255, 255, 255, 0.50) 42.46%, #FFF 84.92%);
}

.gradient-blur-top {
  z-index: 5;
  position: absolute;
  bottom: 380vh;
  width: 200vw;
  height: 79vh;
  flex-shrink: 0;
  background: #FFF;
  filter: blur(100px);
}

.bg-img-top {
  z-index: 1;
  position: absolute;
  top: 0vh;
  left:-60vw;
  width: 3960px;
  height: 180vh;
  flex-shrink: 0;
  background: url(./images/homepage_images/bg-cloud-top.png) lightgray 50% / cover no-repeat;
}

.bg-bottom-gradient-1 {
  z-index: 1;
  position: absolute;
  bottom: 72vh;
  width: 100vw;
  height: 187vh;
  flex-shrink: 0;
  background: linear-gradient(360deg, rgba(185, 177, 199, 0.00) 2%, rgba(255, 255, 255, 0.50) 40.46%, #FFF 84.92%);
}

.bg-bottom-gradient-2 {
  z-index: 1;
  position: absolute;
  bottom: 9vh;  
  left:-3.4vw;
  width: 103vw;
  height: 187vh;
  flex-shrink: 0;
  background: linear-gradient(180deg, rgba(11, 29, 38, 0.00) 31.06%, #0B1D26 108.93%);
}

.gradient-blur-bottom {
  z-index: 5;
  position: absolute;
  top: 310vh;
  width: 200vw;
  height: 60vh;
  flex-shrink: 0;
  background:  rgba(255, 255, 255, 1) 100%;
  filter: blur(100px);
}

.bg-img-bottom {
  z-index: 0;
  position: absolute;
  top: 340vh;
  left: -23vw;
  bottom: 9vh;
  right: -83vw;
  width: 3960px;
  height: 244vh;
  flex-shrink: 0;
  background: url(./images/homepage_images/bg-cloud-bottom.png) lightgray 50% / cover no-repeat;
}

.slider {
  display: none; /* :( go away for now */

  z-index: 10;
  position: absolute;
  top: 30vh;
  left: 92vw;
  bottom: 417vh;
  right: 0.04vw;
  width: 6vw;
  height: 22vh;
  flex-shrink: 0;
  filter: drop-shadow(0px 0px 100px rgba(0, 0, 0, 0.20));
  display: flex;
  flex-direction: row;
  gap: 1vw
}

.slider-bar {
  display: none; /* :( go away for now */

  width: 3px;
  height: 240px;
  flex-shrink: 0;
}

.slider-text {
  display: none; /* :( go away for now */

  width: 42px;
  height: 208px;
  flex-shrink: 0;

  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5.5vh;
}

.slider-text-content {
  display: none; /* :( go away for now */

  color: #FFF;
  text-align: right;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.home-title {
  z-index: 10;
  position:absolute;
  top: 27vh;
  left: 16vw;
  bottom: 395vh;
  right: 25vw;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.home-title-header {
  width: 720px;
  height: 22px;  
  display: flex;
  gap: 32px;
}

.home-title-header-line {
  top: 10px;
  bottom: 10px;
  width: 72px;
  height: 2px;
  flex-shrink: 0;
  background: #FFC132;
}

.home-title-header-text {
  color: #FFC132;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 6px;
  text-transform: uppercase;
}

.home-title-text {
  width: 50vw;
  color: #FFF;
  font-family: Inter;
  font-size: 8vh;
  font-style: normal;
  font-weight: 600;
  line-height: 9vh; /* 113.636% */
  text-align: left;
}

.home-title-scroller {
  width: fit-content;
  height: 24px;
  display: flex;
  flex-direction: row;
  gap: 14px;
}

.home-title-scroller:hover {
  cursor: pointer;
}

.home-title-scroller-text {
  color: #FFF;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.home-title-scroller-arrow {
  width: 16px;
  height: 24px;
  flex-shrink: 0;
}

.home-content {
  z-index: 10;
  position: absolute;
  top: 129vh;
  left: 10vw;
  bottom: 3vh;
  right: 10vw;
  display: flex;
  width: 80vw;
  height: 337vh;
  flex-direction: column;
  align-items: center;
  gap: 192px;
  flex-shrink: 0;
}

.home-content-frame {
  width: 76vw;
  height: 67vh;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.big-number {
  color: #000;
  font-family: Inter;
  font-size: 240px;
  font-style: normal;
  font-weight: 700;
  line-height: 240px; /* 100% */
  opacity: 0.1;
}

.home-01 {
  position: absolute;
  top: -1.3vh;
  right: 64vw;
}

.home-02 {
  position: absolute;
  top: 1vh;
  left: 35vw;
}

.home-03 {
  position: absolute;
  top: 3vh;
  right: 59vw;
  bottom: 44vh;
}

.home-text {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: fit-content;
}

.first-frame-offset {
  padding-top: 7vh;
  padding-left: 8vw;
  padding-bottom: 6vh;

}

.second-frame-offset {
  padding-top: 8vh;
  right: 0vw;
  padding-bottom: 8vh;
}

.third-frame-offset {
  padding-top: 10vh;
  padding-left: 6vw;
  padding-bottom: 107px;
}

.tagline-01{
  width: 19vw;
  height: 22px;
  display: flex;
  gap: 1.25vw;
}

.tagline-02 {
  width: 26vw;
  height: 22px;
  display: flex;
  gap: 1.25vw;
}

.tagline-03 {
  width: fit-content;
  height: 22px;
  display: flex;
  gap: 1.25vw;
}

.tagline-text {
  color: #FFC132;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 6px;
  text-transform: uppercase;
}

.tagline-line {
  top: 10px;
  bottom: 10px;
  right: 10px;
  width: 72px;
  height: 2px;
  flex-shrink: 0;
  background: #FFC132;
}

.home-text-title {
  width: 29vw;
  color: #000;
  font-family: Inter;
  font-size: 3.3vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.home-text-content {
  width: 33vw;
  color: #000;
  font-family: Inter;
  font-size: 1vw;
  font-style: normal;
  font-weight: 700;
  line-height: 3vh; /* 177.778% */
}

.home-img-01 {
  position:relative;
  margin-top: 9vh;
  margin-bottom: 9vh;
  width: 30vw;
  height: 30vw;
  flex-shrink: 0;
  background: url('./images/homepage_images/data-network.png') lightgray 50% / cover no-repeat;
  border-radius: 20px;
}

.home-img-02 {
  position:relative;
  margin-top: 9vh;
  margin-bottom: 2vh;
  left: 0vw;
  width: 30vw;
  height: 30vw;
  flex-shrink: 0;
  background: url('./images/homepage_images/king-mosiah-speaks-to-his-people-1.png') lightgray 50% / cover no-repeat;
  border-radius: 20px;
}

.home-img-03 {
  position: relative;
  margin-top: 6vh;
  margin-right: -0.4vw;
  width: 30vw;
  height: 30vw;
  flex-shrink: 0;
  background: url('./images/homepage_images/joseph-smith-the-hill-cumorah-1.png') lightgray 50% / cover no-repeat;
  border-radius: 20px;
}

.highlight-section {
  display: flex;
  bottom: 0vw;
  padding: 0vh 20vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.highlight-section-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.highlight-title {
  color: #000;
  font-family: Inter;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.highlight-paragraph {
  align-self: stretch;
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 177.778% */
}

.read-more {
  top: 32px;
  width: fit-content;
  height: 22px;
  display: inline-flex;
  gap:14px;
  text-decoration: none;
  padding: 10px;
  left: -10px;
}

.read-more:hover {
  cursor: pointer;
  text-decoration: underline solid #FFC132;
  box-shadow: 0 0 5px 5px #ffc13264;
  border-radius: 5px;
}

.read-more-text {
  color: #FFC132;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: fit-content;
}

.read-more-arrow {
  width: 16px;
  height: 24px;
  flex-shrink: 0;
}

.all-list-container {
  display: flex;
  width: 60vw;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  padding: 2vh 20vw 204px 20vw;

  background: #FFF;
}

.all-list-container-sub {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.articles-list-all {
  padding:32px 0 0 0;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: 3.44418599285vw;
  margin-right: 3.44418599285vw;
}

.articles-list-all-card {
  outline-color:#333;
  padding-top: 1vw;
  padding-bottom: 1vw;
  padding-left: 1.75vw;
  padding-right: 1.75vw;
  text-decoration: none;
}

.articles-list-all-card:hover{
  text-decoration:underline;
  transition: text-decoration 500ms;
  outline: solid;
  cursor:pointer;
}

@media (max-width: 650px) {
  .articles-list-all-card {
    width: 26.4vw;

  }
}

@media (min-width: 650px) and (max-width: 1100px) {
  .articles-list-all-card {
    width: 19.1vw;
  }
}

@media (min-width: 1100px) and (max-width: 1400px) {
  .articles-list-all-card {
    width: 14.8vw;
  }
}

@media (min-width: 1400px) {
  .articles-list-all-card {
    width: 11.8vw;
  }
}

.articles-list-all h4 {
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
}

.splash {
  z-index: 100;
  position: fixed;
  top: 18vh;
  right: 3%;
  width: 35%;
  height: 200px;

  display: flex;
  flex-direction: row;

  color: black;
  background-color: #c5f5e6d4;

  border-radius: 5px;
}

.splash-exit {
  color: gray;
  font-weight: 800;
  font-size: 18px;
  outline: none;
  height: fit-content;
  align-self: flex-start;

  padding: 10px;
}

.splash-exit:hover {
  text-shadow: 0px 0px 10px rgba(128, 128, 128, 0.75);
  animation-name: fade;
  animation-duration: 500ms;
  cursor: pointer;
}

@keyframes fade {
  from {text-shadow: none;}
  to {text-shadow: 0px 0px 10px rgba(128, 128, 128, 0.75);}
  
}

.splash-click {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 5vh 3vw 5vh 2vw;
  text-decoration: none;
}

.splash-click:hover {
  cursor: pointer;
  text-decoration: underline solid #000;
}

.splash-text {
  text-align: left;
  width: 100%;

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.5px;
}

.splash-sub {
  align-self: flex-start;
  align-content: center;

  display: flex;
  flex-direction: row;
}

.splash-sub-text {
  padding: 2px;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
}

.splash-arrow {
  padding: 2px;
  scale: 70%;
}

.splash-img {

}


/* responsive formatting is here. 870px is when it all switches. */
@media screen and (max-width: 870px) {
  .navbar-expanded-menu a {
    font-size: 3vw;
  }

  .landing-page {
    /* height: unset; TODO change this to a good height */
    height: 500vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5vh;
  }

  .footer .copyright {
    font-size: 2vw;
  }

  .footer-links {
    padding: 0 5vw;
    width: 90vw;
  }

  .footer-links * {
    letter-spacing: 1px;
    font-size: 8px;
  }

  .bg-top-gradient-1, .bg-top-gradient-2, .bg-top-gradient-3, .bg-top-gradient-4, .bg-bottom-gradient-1, .bg-bottom-gradient-2, .bg-img-bottom, .bg-img-top, .gradient-blur-bottom, .gradient-blur-top {
    /* display: none; */ 
  }

  .bg-bottom-gradient-1, .bg-bottom-gradient-2 {
    bottom: 0vh;
    height: 100vh;
  }

  .bg-bottom-gradient-1 {
    background: linear-gradient(360deg, rgba(185, 177, 199, 0.00) 2%, rgb(255 255 255 / 79%) 40.46%, #FFF 84.92%);
  }

  .gradient-blur-top {
    height: 50vh;
  }

  .gradient-blur-bottom {
    height: 50vh;
    top: unset;
    bottom: 50vh;;
  }

  .bg-img-top {
    height: 100vh;
    width: 100vh;
  }

  .bg-img-bottom {
    top: unset;
    bottom: -30vh;
    height: 100vh;
    width: auto;
  }

  .home-title {
    position: relative;
    top: unset;
    bottom: unset;
    left: unset;
    right: unset;
    padding-top: 5vh;
    align-items: center;

    width: 80vw;
  }
  .home-title-header {
    height: fit-content;
  }
  .home-title-header-text {
    font-size: 18px;
    text-align: center;
  }
  .home-title-text {
    font-size: 32px;
    line-height: 36px;
    color: #000;
    width: 100%;
    text-align: center;
  }
  .home-title-scroller {
    display: none;
  }
  .home-title-scroller-text {
    color: gray;
    text-align: center;
  }


  .tagline-line, .home-title-header-line {
    display: none;
  }
  .tagline-text {
    font-size: 18px;
  }
  .tagline-01, .tagline-02, .tagline-03, .home-title-header {
    width: 100%;
  }
  
  .home-content {
    position: relative;
    top:unset;
    left: unset;
    bottom: unset;
    right: unset;
    gap: 12px;

    padding-top: 0vh;
    width: fit-content;
  }
  .home-01, .home-02, .home-03 {
    display: none;
  }

  .home-content-frame {
    flex-direction: column;
    align-items: center;
    padding-bottom: 0vh;
    height: fit-content;
  }
  .home-content-frame:has(.home-img-02) {
    flex-direction: column-reverse;
  }
  
  .first-frame-offset, .second-frame-offset, .third-frame-offset {
    padding: 10vh 0 5vh 0;
  }

  .home-text-title, .highlight-title{
    font-size: 28px;
    width: 100%;
  }
  .home-text-content, .highlight-paragraph {
    font-size: 12px;
    line-height: 24px;
    width: 100%;
  }

  .home-img-01, .home-img-02, .home-img-03 {
    width: 40vw;
    height: 40vw;
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 0;
    margin-right: 0;
  }

  .read-more {
    top: 0vh;
    width: 100%;
    height: fit-content;
  }

  .highlight-section {
    padding: 0;
    bottom: unset;
    padding-top: 15vh;
    width: 76vw;
    height: fit-content;
  }


  .article-container {
    width: 80vw;
    padding: 2vh 10vw 204px 10vw;
  }

  .widget-in-cameo{
    width: 100%;
    height: auto;
  }

  .caption {
    width: 60vw;
  }
  .abstract {
    width: 100%;
  }


  .all-list-container {
    width: 80vw;
    padding: 2vh 10vw 204px 10vw;
  }

  iframe {
    height: 100vh !important;
    width: 80vw !important;
  }

  .youtube-iframe {
    height: fit-content !important;
  }

  .bubbles-iframe {
    height: 160vh !important;
    width: 90vw !important;
  }

  .network-iframe {
    height: 150vh !important;
  }

  .similar-verse-iframe {
    height: 110vh !important;
  }

  .connections-iframe {
    width: 85vw !important;
    height: 120vh !important;
  }
}

/* Thanks for reading :) */